import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import Web3 from "web3";
import { Row, Col, Card, Table } from "react-bootstrap";
import { url, withdrawRoi } from "../../../services/api_function";
import { Link } from "react-router-dom";
import { DownloadExcel } from "react-excel-export";
import { web3 } from "./web3/web3Helper";
import {

  tokenAddressDsc,
  token_abi,
  token_address,
  transfer_abi,
  transfer_addres,
  transfer_polygon,
} from "../../config/config";
import { NotificationManager } from "react-notifications";
import moment from "moment";

export const WithdrawRoi = () => {
  const [loading, setLoading] = useState(false);

  const [activeAmount, setActiveAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [approveLoading, setapproveLoading] = useState({});
  const [rejectLoading, setrejectLoading] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("");
  const pageSize = 50;
  useEffect(() => {
    fetchData();
  }, [currentPage, search, paymentMethod]);

  const fetchData = async () => {
    try {
      const userDetails = localStorage.getItem("userDetails");
      const parsedDetails = JSON.parse(userDetails);
      const token = parsedDetails.token;

      const response = await withdrawRoi(
        currentPage,
        { searchQuery: search, paymentMethod: paymentMethod },
        pageSize,
        token
      );
      setFilteredData(response?.data);
      const pages = Math.ceil(response?.totalCount / pageSize);
      setTotalPages(pages > 0 ? pages : 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };



  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const sendTransaction = async () => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(transfer_abi, transfer_addres);
    const token = new web3.eth.Contract(token_abi, tokenAddressDsc);
    try {
      setLoading(true)
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      const senderAddress = accounts[0];
      const recipients = selectedUsers.map((data) => data.user);
      const amounts = selectedUsers.map((data) => data.tokens_received);
      const id = selectedUsers.map((data) => data._id.toString().slice(0, 1));
      console.log(recipients, "recipients:::")
      const objid = selectedUsers.map((data) => data._id);

      const totalAmount = web3.utils.toWei(
        selectedUsers
          .reduce((total, data) => total + data.tokens_received, 0)
          .toString(),
        "ether"
      );

      const refreshamount = web3.utils.toWei(
        selectedUsers
          .reduce((total, data) => total + data.tokens_received, 0)
          .toString(),
        "ether"
      );

      const amountsInWei = amounts.map((amount) =>
        web3.utils.toWei(amount.toString(), "ether")
      );
      const gasPrice = await web3.eth.getGasPrice();
      const tokengas = await token.methods
        .approve(transfer_addres, refreshamount)
        .estimateGas({ from: senderAddress });
      console.log(tokengas, "tokengas");

      const approve = await token.methods
        .approve(transfer_addres, refreshamount)
        .send({
          from: senderAddress,
          gas: tokengas,
          gasPrice: gasPrice,
        });

      if (approve) {
        NotificationManager.success("Approve successfull");
        console.log(recipients,
          amountsInWei,
          totalAmount + 1000,
          tokenAddressDsc, "after approve ::::")
        const gas = await contract.methods
          .multisendToken(
            recipients,
            amountsInWei,
            totalAmount + 1000,

            tokenAddressDsc,
          )
          .estimateGas({ from: senderAddress });

        console.log(gasPrice, gas, "hey");

        var receipt = await contract.methods
          .multisendToken(
            recipients,
            amountsInWei,
            totalAmount+1000,
            tokenAddressDsc,
          )
          .send({
            from: senderAddress,
            gas: gas,
            gasPrice: gasPrice,
          });
        console.log(receipt, ":::");
      }

      const apiResponse = await fetch(url + "/approve-withdraw", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          txHash: receipt.transactionHash,
          user: recipients,
          id: objid,
        }),
      });

      if (apiResponse.status == 200) {

        NotificationManager.success(
          "Transaction successful",
          apiResponse.message
        );
        setLoading(false)
        fetchData();
        window.location.reload()
      } else {
        NotificationManager.error("Failed to send transaction data");
        setLoading(false)
      }
    } catch (error) {
      console.log("Error sending transaction:", error);
      NotificationManager.error("Error sending transaction:");
      setLoading(false)
    }
  };


  const handleCheckboxChange = (data, event) => {
    if (event.target.checked) {
      setSelectedUsers((prev) => [...prev, data]);
    } else {
      setSelectedUsers((prev) =>
        prev.filter((user) => user.user !== data.user)
      );
    }
  };

  const toggleSelectAll = () => {
    if (selectedUsers.length == filteredData.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(filteredData);
    }
  };

  const handleReject = async (user, _id, amount) => {
    setapproveLoading({ ...approveLoading, [user]: true });
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    const apiResponse = await fetch(url + "/reject-withdraw", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user: user,
        amount: amount,
        id: _id,
      }),
    });

    if (apiResponse.status == 200) {
      const errorMessage = await apiResponse.json();
      setapproveLoading(false);
      NotificationManager.success(errorMessage.message);
      fetchData();
    } else {
      if (apiResponse.status == 400) {
        const errorMessage = await apiResponse.json();
        setapproveLoading(false);
        NotificationManager.error(errorMessage.message);
      } else {
        NotificationManager.error("Failed to send transaction data");
        setapproveLoading(false);
      }
    }
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  const totalToken = filteredData.reduce((acc, data) => acc + Number(data.tokens_received), 0);
  useEffect(() => {
    if (selectedUsers) {
      console.log(selectedUsers,"dddd")
      const totalAmount = web3.utils.toWei(
        selectedUsers
          .reduce((total, data) => total + data.tokens_received, 0)
          .toString(),
        "ether"
      );
      setActiveAmount(Number(totalAmount/1e18))
    } else {
      setActiveAmount(0)

    }

    
  }, [selectedUsers])
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>

        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Withdrawal Request Details
              </Card.Title>
              <button
                type="button"
                class="btn btn-success"
                onClick={sendTransaction}
                style={{ marginRight: "50px" }}
                disabled={loading}
              >
                {loading ? "Approving....." : "Approve"}

              </button>

            </Card.Header>

            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <td>
                      <strong>
                        {" "}
                        <input
                          type="checkbox"
                          checked={selectedUsers?.length == filteredData?.length}
                          onClick={toggleSelectAll}
                        />
                      </strong>
                    </td>
                    <th>
                      <strong>No</strong>
                    </th>

                    <th>
                      <strong>User</strong>
                    </th>

                    <th>
                      <strong>User ID</strong>
                    </th>


                    <th>
                      <strong>Amount</strong>
                    </th>
                    <th>
                      <strong>Token Amount</strong>
                    </th>
                    <th>
                      <strong>Type</strong>
                    </th>
                    <th>
                      <strong>Reject</strong>
                    </th>
                    <th>
                      <strong>Date&Time</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="8" ><strong className="text-warning">Total Token Required:</strong></td>
                    <td><strong className="text-warning fw-bolder">{totalToken?.toFixed(2)}</strong></td>
                  </tr>
                  <tr>
                    <td colSpan="8" ><strong className="text-success">Selected Token Amount :</strong></td>
                    <td><strong className="text-warning fw-bolder">        {typeof activeAmount === 'number' ? activeAmount.toFixed(2) : '0.00'}
                    </strong></td>
                  </tr>
                  {filteredData && filteredData?.length > 0 ? (
                    filteredData?.map((Data, index) => (
                      <>

                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedUsers.some(
                                (u) => u?._id == Data?._id
                              )}
                              onChange={(event) =>
                                handleCheckboxChange(Data, event)
                              }
                            />
                          </td>
                          <td>{(currentPage - 1) * pageSize + index + 1}</td>

                          <td>{Data?.user}</td>
                          <td>
                            {(Data?.Name)}
                          </td>
                          <td>{Number(Data?.withdrawAmount)}</td>

                          <td>
                            {Number(Data?.tokens_received).toFixed(2)}
                          </td>
                          <td className="text-center">{Data?.wallet_type}</td>
                          <td>
                            {" "}
                            <button
                              style={{
                                cursor: "pointer",
                                padding: "5px 10px",
                                color: "white",
                                backgroundColor: "red",
                                fontWeight: "bold",
                                border: "none",
                                borderRadius: "4px",
                                outline: "none",
                              }}
                              onClick={() =>
                                handleReject(
                                  Data?.user,
                                  Data?._id,
                                  Data?.withdrawAmount
                                )
                              }
                              disabled={approveLoading[Data?.user]}
                            >
                              {rejectLoading[Data?.user]
                                ? "Rejecting..."
                                : "Reject"}
                            </button>
                          </td>
                          <td>{moment((Data?.createdAt)).fromNow()}</td>

                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No data found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default WithdrawRoi;
